<template>
    <vs-switch v-model="status" @input="changeStatus($event)" :disabled="params.value" />
</template>

<script>

  import { mapActions } from 'vuex'
    export default {
        name: 'CellRendererStatus',
        data() {
          return {
            status: '',
          }
        },
        mounted(){
          this.status = this.params.data.status;
        },
         methods: {
          ...mapActions({
            clearForm: 'banner/clearForm',
            fetchAndSetBanners: 'banner/fetchAndSetBanners',
            updateBannerStatusAction: 'banner/updateBannerStatusAction',
          }),
          changeStatus($event){
            this.status = $event;
            this.updateBannerStatusAction({data:{id: this.params.data.id, status: $event}})
              .then(response => {
                if(response.status === 200) {
                   let params = new URLSearchParams();
                    params.append("page", this.page);
                    this.fetchAndSetBanners({query: params});
                  this.$vs.notify({ 
                      title: 'Banner Status',
                      text: 'Banner status changed successfully.',
                      color: 'success',
                      position: 'top-right'
                  });
                }
              });
          }
        }
    }
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
